import React from 'react';
import logo from './img/logo.png';
import smallLogo from './img/small-logo.png';
import button from './img/button.png';
const Home = () => {
    return (
        <div>
            <div  className="bg">
                <div className="img-wrapper">
                    <img className="logo" src={logo} alt={'logo'}/>
                    <img className="logo-small" src={smallLogo} alt={'logo'}/>
                </div>
                <a href={"#"}>
                    <img className="button" src={button} alt={'button'}/>
                </a>
            </div>
            <footer className="footer">
                <div>
                    <p>© 2024 Mindful Band</p>
                </div>
                <div>
                    <p>Contact us: <a href="mailto:olena@mindful.band">olena@mindful.band</a></p>
                </div>
                <div className="terms-wrapper">
                    <p><a href={"./Rayana.TermsofService.html"}>Terms of service</a></p>
                    <p><a href={"./Rayana.PrivacyPolicy.html"}>Privacy policy</a></p>
                </div>

            </footer>
        </div>
    );
};

export default Home;
